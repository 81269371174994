import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../components/Navbar/Navbar';
import StageNavbar from '../components/Stages/StageNavbar';
import { withRouter } from "react-router-dom";
import { clinic0, clinic1, clinic2, clinic3, clinic4, clinic5 } from '../components/Navbar/NavbarData';
import axiosInstance from '../axios';
import AlosertPreop from "./Preop/AlosertPreop";
import DittaPreop from "./Preop/DittaPreop";
import HishamPreop from './Preop/HishamPreop';
import HaiderPreop from './Preop/HaiderPreop';
import MunirPreop from "./Preop/MunirPreop";
import SamiPreop from "./Preop/SamiPreop";
import ShuaibPreop from "./Preop/ShuaibPreop";
import VishalPreop from "./Preop/VishalPreop";
import RafiqPreop from "./Preop/RafiqPreop";
import ContactUs from '../components/ContactUs';
import { Kenton, London, Manchester, Manchester2, Romford } from './BranchData.js';


function Preop() {

    const [name, setName] = useState({name: '', logo: '', email: ''});
    const contactRef = useRef();
    const [preopInsturctions, setpreopInsturctions] = useState();
    const [branchData, setbranchData] = useState();
    const [doctorId, setDoctorId] = useState();

    useEffect(() => {
        axiosInstance.get('clinic-detail/').then((res) => {
            let data;
            let clinic = res.data.clinic_name;
            let doctorSelection = res.data.doctor_name;
            
            if(clinic === 1 && doctorSelection === 2){
                data = clinic0;
                setpreopInsturctions(<AlosertPreop />);
                setbranchData(Kenton);
                setDoctorId("Dr Alosert");
            } else if(clinic === 3) {
                data = clinic1;
                setpreopInsturctions(<HaiderPreop />);
                setbranchData(Manchester);
                setDoctorId("Dr Haider");
            } else if(clinic === 4) {
                data = clinic2;
                setpreopInsturctions(<MunirPreop />);
                setbranchData(Manchester2);
                setDoctorId("Dr Munir");
            } else if(clinic === 1 && doctorSelection === 3) {
                data = clinic3;
                setpreopInsturctions(<SamiPreop />);
                setbranchData(Kenton);
                setDoctorId("Dr Sami");
            } else if(clinic === 0 && doctorSelection === 0) {
                data = clinic4;
                setpreopInsturctions(<ShuaibPreop />);
                setbranchData(London);
                setDoctorId("Dr Shuaib");
            } else if(clinic === 1 && doctorSelection === 0) {
                data = clinic4;
                setpreopInsturctions(<ShuaibPreop />);
                setbranchData(Kenton);
                setDoctorId("Dr Shuaib");
            } else if(clinic === 2) {
                data = clinic5;
                setpreopInsturctions(<VishalPreop />);
                setbranchData(Romford);
                setDoctorId("Dr Vishal");
            } else if(clinic === 0 && doctorSelection === 1) {
                data = clinic4;
                setpreopInsturctions(<RafiqPreop />);
                setbranchData(London);
                setDoctorId("Dr Rafiq");
            }

            setName({ name: data.name, logo: data.logo, email: data.email, reference: contactRef });
        });
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <Navbar {...name} /><hr className="m-0 p-0" />
            <StageNavbar reference={contactRef} doctorId={doctorId} /><hr className="m-0 p-0" />
            { preopInsturctions }
            <ContactUs reference={contactRef} branchData={branchData} doctorId={doctorId} />
        </div>
    );
}

export default withRouter(Preop);
