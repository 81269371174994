import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../components/Navbar/Navbar';
import StageNavbar from '../components/Stages/StageNavbar';
import { withRouter } from "react-router-dom";
import { clinic0, clinic1, clinic2, clinic3, clinic4, clinic5 } from '../components/Navbar/NavbarData';
import axiosInstance from '../axios';
import DrAlosert from "./Doctors/DrAlosert";
import DrHaider from './Doctors/DrHaider';
import DrMunir from "./Doctors/DrMunir";
import DrSami from "./Doctors/DrSami";
import DrShuaib from './Doctors/DrShuaib';
import DrVishal from "./Doctors/DrVishal";
import DrRafiq from "./Doctors/DrRafiq";
import { Kenton, London, Manchester, Manchester2, Romford } from './BranchData.js';
import ContactUs from '../components/ContactUs';



function Doctor() {
    const [name, setName] = useState({name: '', logo: '', email: ''});
    const contactRef = useRef();
    const [doctor, setDoctor] = useState();
    const [branchData, setbranchData] = useState();
    const [doctorId, setDoctorId] = useState();

    useEffect(() => {
        axiosInstance.get('clinic-detail/').then((res) => {
            let data;
            let clinic = res.data.clinic_name;
            let doctorSelection = res.data.doctor_name;
            if(clinic === 1 && doctorSelection === 2){
                data = clinic0;
                setDoctor(<DrAlosert />);
                setbranchData(Kenton);
                setDoctorId("Dr Alosert");
            } else if(clinic === 3) {
                data = clinic1;
                // setDoctor(<DrDitta />);
                setDoctor(<DrHaider />);
                setbranchData(Manchester);
                setDoctorId("Dr Haider");
            } else if(clinic === 4) {
                data = clinic2;
                setDoctor(<DrMunir />);
                setbranchData(Manchester2);
                setDoctorId("Dr Munir");
            } else if(clinic === 1 && doctorSelection === 3) {
                data = clinic3;
                setDoctor(<DrSami />);
                setbranchData(Kenton);
                setDoctorId("Dr Sami");
            } else if(clinic === 0 && doctorSelection === 0) {
                data = clinic4;
                setDoctor(<DrShuaib />);
                setbranchData(London);
                setDoctorId("Dr Shuaib");
            } else if(clinic === 2) {
                data = clinic5;
                setDoctor(<DrVishal />);
                setbranchData(Romford);
                setDoctorId("Dr Vishal");
            } else if(clinic === 0 && doctorSelection === 1) {
                data = clinic4;
                setDoctor(<DrRafiq />);
                setbranchData(London);
                setDoctorId("Dr Rafiq");
            }

            setName({ name: data.name, logo: data.logo, email: data.email, reference: contactRef });
        });
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Navbar {...name} /><hr className="m-0 p-0" />
            <StageNavbar reference={contactRef} doctorId={doctorId} /><hr className="m-0 p-0" />
            {doctor}
            <ContactUs reference={contactRef} branchData={branchData} doctorId={doctorId} />
        </div>
        
    )
}

export default withRouter(Doctor);
