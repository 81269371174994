import React, {useEffect, useState, useRef} from 'react';
import { withRouter } from "react-router-dom";
import axiosInstance from '../axios';
import Navbar from '../components/Navbar/Navbar';
import StageNavbar from '../components/Stages/StageNavbar';
import StageCard from '../components/PackageCard/StageCard';
import { clinic0, clinic1, clinic2, clinic3, clinic4, clinic5 } from '../components/Navbar/NavbarData';
import Hero from '../components/HeroSection/Hero';
import { homeObj0, homeObj1, homeObj2, homeObj3, homeObj4, homeObj5, homeObj6, homeObj7 } from './HeroData';
import ContactUs from '../components/ContactUs';
import { Kenton, London, Manchester, Manchester2, Romford } from './BranchData.js';

const Home = () => {

    const [name, setName] = useState({name: '', logo: '', email: ''});
    const [drData, setdrData] = useState();
    const [branchData, setbranchData] = useState();
    const contactRef = useRef();
    const [doctorId, setDoctorId] = useState();
    const [username, setUsername] = useState();

    useEffect(() => {
        axiosInstance.get('clinic-detail/').then((res) => {

            let data;
            let clinic = res.data.clinic_name;
            let doctorSelection = res.data.doctor_name;
            setUsername(res.data.username);
            if(clinic === 1 && doctorSelection === 2){
                data = clinic0;
                setdrData(homeObj0);
                setbranchData(Kenton);
                setDoctorId("Dr Alosert");
            } else if(clinic === 3) {
                data = clinic1;
                setdrData(homeObj7);
                setbranchData(Manchester);
                setDoctorId("Dr Haider");
            } else if(clinic === 4) {
                data = clinic2;
                setdrData(homeObj2);
                setbranchData(Manchester2);
                setDoctorId("Dr Munir");
            } else if(clinic === 1 && doctorSelection === 3) {
                data = clinic3;
                setdrData(homeObj3);
                setbranchData(Kenton);
                setDoctorId("Dr Sami");
            } else if(clinic === 0 && doctorSelection === 0) {
                data = clinic4;
                setdrData(homeObj4);
                setbranchData(London);
                setDoctorId("Dr Shuaib");
            }  else if(clinic === 1 && doctorSelection === 0) {
                data = clinic4;
                setdrData(homeObj4);
                setbranchData(Kenton);
                setDoctorId("Dr Shuaib");
            } else if(clinic === 2) {
                data = clinic5;
                setdrData(homeObj5);
                setbranchData(Romford);
                setDoctorId("Dr Vishal");
            } else if(clinic === 0 && doctorSelection === 1) {
                data = clinic4;
                setdrData(homeObj6);
                setbranchData(London);
                setDoctorId("Dr Rafiq");
            }

            setName({ name: data.name, logo: data.logo, email: data.email, reference: contactRef });
        });

    }, []);

    return (
        <div>
            <Navbar {...name} /><hr className="m-0 p-0" />
            <StageNavbar reference={contactRef} doctorId={doctorId} username={username} /><hr className="m-0 p-0" />
            <div className="px-3" >
                <div style={{background: '#fafafa'}}>
                    <StageCard reference={contactRef} />
                </div>
            </div>
            <div className="px-3">
                <div>
                    <Hero {...drData} />
                </div>
                <ContactUs reference={contactRef} branchData={branchData} doctorId={doctorId} />
            </div>
        </div>
    );
};

export default withRouter(Home);