import React from 'react';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import '../../css/Doctors.css';

function DrHaider() {
    return (
        <div className="container doctor-profile-page my-5">
            <h2 className="doctor-profile-page-name">Dr Sayed Haider Ali Gillani</h2>
            <img src="https://s3.eu-central-1.wasabisys.com/patient-portal/dr_images/dr_h.png" alt="Dr Haider" className="float-sm-end" style={{height:'300px'}} />
            <ul className="doctor-list-style-none p-0">
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Dr Gillani is a highly experienced and innovative hair transplant surgeon with extensive experience in the FUE methodology.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  He specialises in hairline designs with his innovative methods allowing for a seamless natural look and feel as well as speciality in Afro-Caribbean hair types. Having performed over 1000 procedures, it is no surprise that he is one of the sought after surgeons in the UK.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  Dr Gilani is a dedicated medical professional, with a diverse and international background in healthcare. After earning a Medical degree (MD) from overseas, he gained extensive experience across surgical and medical specialties. As well as gaining multiple accreditations in advanced clinical anatomy and surgical electives in the specialties of orthopaedics, plastic surgery and vascular surgery, combined with his background focused on general surgery and general medicine.</li>
                <li><IoMdCheckmarkCircleOutline size="20px" color="#191667" />  With a Career spanning Europe, Asia and the UK, he has worked extensively within multidisciplinary teams in a variety of global healthcare settings including in the NHS. This collaborative experience has enabled him to cultivate a patient centred approach while effectively navigating complex clinical environments and strengthening teamwork to achieve the highest standards of patient care.</li>
            </ul>
        </div>
    );
}

export default DrHaider
