export const homeObj0 = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Dr Mahdi Alosert',
    headline: 'An experienced Principal Hair Restoration Surgeon',
    description:
      'With 27-year-Multicultural Medical Experience, having qualified in 1994; Dr Alosert is a Consultant in Emergency Medicine, an experienced Musculoskeletal Sonographer and MSK ultrasound Injection Therapist.',
    buttonLabel: 'Read More',
    imgStart: '',
    img: 'https://patient-portal.s3.fr-par.scw.cloud/website/images/Doctors/DrA.png',
    alt: 'Credit Card',
};

export const homeObj1 = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Dr Mohammed Ditta',
    headline: 'Vast experience in performing FUE / hair transplant procedures',
    description:
      'With vast experience, Dr Ditta has performed more than 1200 FUE hair transplant surgeries',
    buttonLabel: 'Read More',
    imgStart: '',
    img: 'https://patient-portal.s3.fr-par.scw.cloud/website/images/Doctors/DrD.png',
    alt: 'Credit Card',
};

  
export const homeObj2 = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Dr Masood Munir',
    headline: 'Lead surgeon specialising in FUE / Afro hair transplant procedures',
    description:
      'Well established surgeon with a wealth of experience and charisma. Principle medical practicioner in the hair restoration sector.',
    buttonLabel: 'Read More',
    imgStart: '',
    img: 'https://patient-portal.s3.fr-par.scw.cloud/website/images/Doctors/DrM.png',
    alt: 'Vault',
};
  
export const homeObj3 = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Dr Sami Mirza',
    headline: 'Highly sought after surgeon',
    description:
      "Dr Mirza is a very well-established Dr with a wealth of experience in many of areas of expertise. In demand due to the speciality in Afro and Unshaven hair transplant method and having worked on many celebrity clientele.",
    buttonLabel: 'Read More',
    imgStart: 'start',
    img: 'https://patient-portal.s3.fr-par.scw.cloud/website/images/Doctors/DrS.png',
    alt: 'Vault',
};
  
export const homeObj4 = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Dr Shuaib Mir',
    headline: 'Performed over 1500 successful cases in the hair restoration industry',
    description:
      'Dr Shuaib specialises in both male and female transplantation, Afro-Caribbean hair, beard and eyebrow transplants. He is also highly skilled in using his unique artistic skill to precisely design hairlines for patients of various demographics.',
    buttonLabel: 'Read More',
    imgStart: 'start',
    img: 'https://patient-portal.s3.fr-par.scw.cloud/website/images/Doctors/DrShuaib.png',
    alt: 'Vault',
};

export const homeObj5 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Dr Vishal Nayak',
  headline: 'Lead Hair Transplant Surgeon',
  description:
    'Performed over 1000 successful surgeries including celebrity clientele such as England Footballers and Boxers.',
  buttonLabel: 'Read More',
  imgStart: 'start',
  img: 'https://patient-portal.s3.fr-par.scw.cloud/website/images/Doctors/DrV.png',
  alt: 'Vault',
};

export const homeObj6 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Dr Zakar Rafiq',
  headline: 'Clinical Lead Surgeon And Trainer',
  description:
    'Highly qualified having performed over 3000 successful surgeries, making him one of the most experienced surgeons in the UK.',
  buttonLabel: 'Read More',
  imgStart: 'start',
  img: 'https://patient-portal.s3.fr-par.scw.cloud/website/images/Doctors/DrRc.png',
  alt: 'Vault',
}

export const homeObj7 = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Dr Haider Gillani',
  headline: 'Vast experience in performing FUE / hair transplant procedures',
  description: "Dr Gillani is a highly experienced and innovative hair transplant surgeon with extensive experience in the FUE methodology.",
  buttonLabel: 'Read More',
  imgStart: '',
  img: 'https://s3.eu-central-1.wasabisys.com/patient-portal/dr_images/dr_h.png',
  alt: 'Credit Card',
};
